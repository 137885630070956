// font-family:'Arial Rounded MT W01 Bold';
// font-family:'Arial Rounded MT W01 Light';

.mainCallToAction a.cta-button.subsite { display: none; }

// Maintains aspect ratio 16:9 for video on mobile
.postVideoObjectEmbed,
.embed-container {
  width: 100%;
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  margin: $spacer * 2 0;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// Add line at bottom of menu admin
.menuAdminContainer {
  border-bottom: 1px solid rgba($brand-primary, 0.2);
}
// Add shadow to mobile menu
@media (max-width: map-get($breakpoints, lg)) {
  .menuMain {
    box-shadow: 0 15px 20px rgba(black, 0.2);
  }
}
// Fix burger menu border radius and margins
@media (max-width: map-get($breakpoints, sm)) {
  .menuMainAlt {
    border-radius: 0;
  }
  .content.headerContent {
    margin-bottom: 0;
  }
  .mainLogo {
    margin-bottom: 1rem;
  }
}

// Hide search form input
@media (min-width: map-get($breakpoints, xl)) {
  .header-search #siteSearch {
    display: none;
  }
}

// Tagline for phone number
.tagline {
  margin-left: 1rem;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 1.5rem !important;
  strong {
    color: $brand-secondary;
    display: block;
    font-size: 1rem;
  }
  @media (max-width: map-get($breakpoints, sm)) {
    font-size: 1rem;
    margin-left: 0;
    margin-top: 0 !important;
  }
}

// Home intro phone number
.homeIntro {
  p {
    font-size: 2.5rem;
    a:not(.button) {
      text-decoration: none;
      &:hover {
        color: $brand-secondary;
      }
    }
    strong {
      color: $brand-secondary;
    }
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 578px) {
    p {
      display: flex;
      flex-direction: column;
    }
  }
}

// Make logo smaller on < 374px for iPhone SE
@media (max-width: 374px) {
  .mainLogo {
    width: $logo-width * 0.5;
    height: $logo-height * 0.5;
  }
}

// Stats styling
.homeImpactWrapper {
  table tbody {
    align-items: flex-start;
  }
  td:nth-child(1) {
    order: 2;
    padding-top: 1em;
  }
  td:nth-child(2) {
    order: 3;
    font-size: 1.125em;
    font-family: 'Arial Rounded MT W01 Bold', Arial, sans-serif;
    color: $brand-primary;
  }
  td:nth-child(3) {
    order: 1;
    img {
      width: 125px;
      height: 125px;
    }
  }
}

// Footer stuff
.footerBox.footerBox2 {
  ul {
    li {
      a {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}
.footerBox.footerBox4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  img {
    max-height: 120px;
    width: 120px;
    margin: 1rem;
  }
}

// Related a bit to https://www.bugherd.com/projects/169226/tasks/16
// They have a lot of long, top level navs
// Instead of increasing the nav breakpoint, let's just tighten them up a bit in the danger zone
@media (min-width: $nav-breakpoint + 1px) and (max-width: 1140px) {
  .menuMain .topLevel li.level1 > a {
    font-size: .9em;
    padding: 10px 12px;
  }

  .topLevel > li:nth-last-child(5) > .level2 > .level2 > .level3 {
    left: -100%;
  }
}

// AW - DES-3786
.appealInteractionsWrapper {
  display: none;
}


// Subsite - Style
body.subsite.say-their-name-day {

  // Fonts
  p { font-family:'VAG Rounded Next W05 Regular', sans-serif; }
  h1, h2, h3, h4, h5, h6, a { font-family:'VAG Rounded Next W05 Medium', sans-serif; }
  nav.menuMain a { font-family:'VAG Rounded Next W05 Medium', sans-serif; }

  // Logo
  a.mainLogo {
    position: relative;
    background-image: url($assets-path + 'subsite-logo.png');
    width: 170px;
    height: 60px;
    margin: 0 0 1rem 0;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: 1025px) {
      width: 340px;
      height: 110px;
      margin: 1rem 0;
    }
  }

  .subsiteBody h1 { color: $brand-primary; }

  // Hide things
  .mainCallToAction ul.socialIcons,
  .mainCallToAction a:not(.menuMainAlt),
  .headerTextSubsite,
  .header-search,
  .menuAdminBasket {
    display: none;
  }

@media (min-width: 769px) {
  .mainCallToActionButtons a.cta-button.subsite { display: block !important; }
}

@media (max-width: 768px) {
  nav.menuMain a.cta-button.subsite { display: block !important; }
}

  .subsiteFeed {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

}
