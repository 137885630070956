//
// Tint and shade
//
@function tint($color, $percentage) {
    @return mix(white, $color, 100% - $percentage);
}

@function shade($color, $percentage) {
    @return mix(black, $color, 100% - $percentage);
}


//
// Environment
//



//
// Config values
//

$platform-name: 'sands-miscarriagestillbirthnewborndeathsupport';
$assets-path: "../assets/";

//
// Colours
//

$brand-primary: #5a4099;
$brand-secondary: #41c4dc;

$brand-grey: rgb(126, 128, 130);


// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;


// Utility colours


//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 960px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo: 'logo2.svg';
$logo-width: 514px;
$logo-height: 100px;


//
// Typography
//

$font-family-base: Arial, sans-serif;

$headings-font-family: 'Arial Rounded MT W01 Bold', Arial, sans-serif;
$headings-font-weight: normal;

$font-size-h1: 2.5em !default;
$font-size-h2: 1.75em !default;
$font-size-h3: 1.5em !default;
$font-size-h4: 1.25em !default;
$font-size-h5: 1.125em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em; // This will only affect headings within post content

// Links
$link-colour: $brand-secondary;

// Icon font


//
// Buttons
//

$btn-font-family: $headings-font-family;
$btn-border-radius: 2em;
$btn-padding-x: 1.5em;
$btn-padding-y: .75em;
$btn-text-colour: white;


// Donate button
$donate-btn-colour: white;


//
// Social icons
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true

$social-icons-colour: $brand-primary; // A colour, or 'brand'
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'


// Header specific
$header-background-colour: white;

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//


// Field groups


//
// Tables
//



//
// Card
//

$card-gap-width: 1px;
$card-border: 0;
$card-text-align: center;
$card-heading-font-size: $font-size-h4;
$card-details-background-colour: transparent;
$card-hover-details-background-colour: transparent;
$card-footer-background-colour: transparent;
$card-heading-colour: $brand-primary;


// Card text overlay

// Card text over

// Card side by side


// Card hover state
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;


//
// Menu admin
//

$menu-admin-background-colour: white;
$menu-admin-link-colour: $brand-primary;


//
// Header
//


// Header content

// Tagline
// $tagline-enabled: true;
$tagline-colour: $brand-primary;
$tagline-font-size: $font-size-h4;
$tagline-breakpoint: 0;
$tagline-above-cta-breakpoint: map-get($breakpoints, sm);


// Main call to action
$cta-btn-breakpoint: map-get($breakpoints, md);

// Search
// $header-search-margin-right: 5px;
// $header-search-breakpoint: map-get($breakpoints, xl);
$header-search-enabled: false;
$nav-search-enabled: true;
$header-search-input-background-colour: rgba($brand-primary, 0.1);
$header-search-button-background-colour: transparent;

// Social icons


//
// Sticky header
//



//
// Navigation
//

$nav-font-family: $headings-font-family;
$nav-background-colour: $brand-primary;


// Top level items
$nav-top-level-item-colour: rgba(white, 0.8);
$nav-top-level-item-font-size: 1.125em;
$nav-top-level-item-hover-colour: rgba(white, 1);

// Submenus
$nav-submenu-width: 250px;

// Burger button
$burger-btn-bar-breakpoint: map-get($breakpoints, sm); // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-align-items: center; // left, center, right
$nav-normal-mobile-background-colour: white;
$nav-normal-mobile-top-level-item-colour: $brand-primary;


//
// Carousel
//

$carousel-max-width: 100%;
$carousel-details-style: below; // overlay, adjacent or below
$carousel-details-position-y: bottom; // top, center, bottom
// $carousel-details-margin-y: 2rem;
$carousel-details-background-colour: $brand-primary;
$carousel-details-padding: $spacer * 2;
// $carousel-details-margin-x: calc((100vw - 1230px) / 2);
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-details-max-width: 450px;
$carousel-read-more-background-colour: $brand-secondary;
// $carousel-read-more-colour: $brand-primary;
// $carousel-image-overlay: radial-gradient(807.08px at 1.38% 100.05%, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%); // Add a colour value or gradient here


// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel after breakpoint


//
// Home intro
//

$home-intro-background-colour: transparent;
$home-intro-colour: $brand-primary;
$home-intro-margin-top: 0;
$home-intro-font-size: $font-size-h4;


//
// Home features
//

// $home-features-background-colour: rgba($brand-secondary, 0.1);
$home-features-max-width: calc(100% - 20px);
$home-features-padding-top: $spacer * 0;
$home-features-padding-bottom: $spacer * 2;


//
// Impact stats
//

$impact-stats-background-colour: tint($brand-primary, 10%);
// $impact-stats-background-image: 'family.jpg';
// $impact-stats-background-image-opacity: .2;
$impact-stats-padding-y: $spacer * 2;


// Heading
$impact-stats-heading-colour: $grey-dark;
$impact-stats-heading-enabled: false; //true or false

// Individual stat

// Figure
$impact-stats-figure-colour: $brand-primary;

// Summary
$impact-stats-summary-font-size: 1.125em;
$impact-stats-summary-colour: $brand-primary;


//
// Home feeds
//


// Feeds title
$feeds-title-text-align: center; // left, center, right

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//
$footer-prefab: 4;
$footer-background-colour: tint($brand-primary, 10%);
$footer-colour: $brand-primary;
$footer-link-colour: $brand-primary;


// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: false;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: false;

// Newsletter
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-background-colour: tint($brand-primary, 10%);
$newsletter-colour: $brand-primary;
$newsletter-hero-padding-y: $spacer * 2;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-heading-font-size: $font-size-h4;
$newsletter-button-colour: white;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer * 1;


//
// Page title
//

$page-title-margin-top: 2em;
$page-title-colour: $brand-primary;



//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts


// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//

$blockquote-background-colour: transparent;
$blockquote-text-align: left; // left, center, right
$blockquote-colour: $brand-primary;



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//

// Donation amounts


//
// Quick giving panel
//
// $quick-giving-background-colour: transparent;



//
// Home quick giving panel
//
$home-quick-giving-max-width: $container-max-width;
$home-quick-giving-margin-top: -1rem;
$home-quick-giving-background-colour: $brand-primary;
$home-quick-giving-padding-y: $spacer * 2;
// $home-quick-giving-type: adjacent; // simple, adjacent or overlay
$home-quick-giving-donation-amount-background-colour: rgba(white, 0.4);
$home-quick-giving-donate-btn-background-colour: $brand-secondary;
$home-quick-giving-heading-colour: white;

// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//

$blog-details-enabled: false;

//
// FAQs
//



//
// Shop
//


// Departments list

// Product-price

// Product post


// Subsite
//

$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: false;
$subsite-hide-header-search: false;
$subsite-hide-main-cta: false;
$subsite-content-max-width: $container-max-width;
$subsite-title-font-size: $font-size-h1;

// Subsite logo
$subsite-logo: "subsite-logo.png";
$subsite-logo-width: 340px;
$subsite-logo-height: 110px;
$subsite-logo-margin: 1rem 0 1rem 0;

$subsite-nav-margin-top: $spacer;
$subsite-nav-margin-bottom: 0;

//
// Cookie consent
//
