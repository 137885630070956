@mixin appeal-field(
  $body-class,
  $background-colour,
  $background-image,
  $text-colour,
  $icon-image,
  $icon-size,
  $icon-mobile-size,
  $icon-animation,
  $appeal-content-colour
) {

  body.appeal-#{$body-class},
  body.donate.#{$body-class} {
  background-color: $background-colour;
  background-image: url($assets-path + $background-image);
  background-position: center 0;
  &.userLoggedIn{
    background-position: center 60px;
  }
  background-size: 2500px;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    background-position: center 40px;
  }
}

  body.appeal-support-say-their-name {
    color: $white;

    // hide live chat bot
    .bcFloat { display: none !important; }

    li.carouselSlide { display: none; }

    h1{
      color:white;
    }

    a.button.callToActionBtn {
      background-color: $cta-btn-background-colour;
    }

    .post .appealActionsWrapper {
      position: relative;
      background-color: #0000;
      padding:0;
    }

    .contentBlockWrapper{
      margin-top:0;
    }

    .postContent {
      width: 100%;
      @media (min-width: 768px) {
        & > p{
          padding-right:200px;
        }
      }
    }

    .postAside {
      display: none;
    }

    .shareWrapper {
      display: none;
    }

    .totaliserPanel {
      display: none;
    }

    .appealInteractionsWrapper {
      display: block;
    }

    .appealInteractions {
      margin: 0;
      max-width: 100%;
      padding: 0;

      .tabsWrapper {
        .tabs {
          border: none;
        }
      }
    }

    .appealComments {
      border: 0;
      margin: 0 auto;
      padding: 0;

      // Move icon field to fit background-image
      margin-top: 100px;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }

    .commentsListWrapper {
      margin: 0;
    }

    .commentsList {
      padding: 0;
      position: relative;
      min-height: 500px;
      margin: 0 auto 100px; // Add margin at the bottom so comments clear footer
    }

    .commentsList {
      .commentDonation {
      position: absolute; // relative to the .appealInteractionsWrapper
      top: 0; // Values altered with JS
      left: 0;
      border: 0;
      padding: 0;
      margin: 0;
      display: inline-block;
      opacity: 0;
      transition: opacity 4s;

      img.commentProfilePic { display: none; }

      &.commentDonation--positioned {
        opacity: 1;
      }

      --icon-size: $icon-size;

      // This is the icon for each comment on the field
      &:before {
        content: '';
        display: block;
        background-image: url($assets-path + $icon-image);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        width: $icon-size;
        height: $icon-size;
        margin-left: ($icon-size / 2) * -1;
        animation: $icon-animation;
        -webkit-animation: $icon-animation;
        -moz-animation: $icon-animation;
        -ms-animation: $icon-animation;
        -o-animation: $icon-animation;
        @media (max-width: map-get($breakpoints, md)) {
          width: $icon-mobile-size;
          height: $icon-mobile-size;
        }
      }

      // Delay each icon a bit differently so they move not all in sync
      &:nth-child(2n) {
        &:before {
        animation-delay: .5s;
        }
      }

      &:nth-child(3n) {
        &:before {
          animation-delay: 1s;
        }
      }

      &:nth-child(4n) {
        &:before {
          animation-delay: 1.5s;
        }
      }

      &:nth-child(5n) {
        &:before {
          animation-delay: 2s;
        }
      }

      &:nth-child(6n) {
        &:before {
          animation-delay: 2.5s;
        }
      }

      &:nth-child(7n) {
        &:before {
          animation-delay: 3s;
        }
      }

      &:nth-child(8n) {
        &:before {
          animation-delay: 3.5s;
        }
      }

      // Reveal the comment when you hover on the icon
      &:hover {
        .commentContentWrapper {
          display: block;
        }
      }
    }

    // Flip every two flower for less linear and cleaner field

  .commentDonation {
    &:nth-child(even) {
      &:before {
        //background-image: url($assets-path + "flower-icon-flip.svg");
      }
    }
  }

  }

    // Styles for the comment box
    .commentsList .commentContentWrapper {
      display: none;
      width: auto;
      width: 300px;
      background: $brand-primary;
      border-radius: 5px;
      text-align: center;
      padding: 20px;
      position: absolute;
      transform: translateX(-50%);
      margin-top: 20px;
      z-index: 2;

      // This is the little upward triangle above the comment box
      &:before {
        content: '\f0d8';
        font-family: 'FontAwesome';
        position: absolute;
        top: -18px;
        margin-left: -6px;
        font-size: 20px;
        color: $brand-primary;
        // animation: move-twink-back 500s linear infinite; // Not sure what this does
      }

      @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0;
        transform: none;
        border-radius: 0;
        padding: 15px; 
        &:before {
          display: none;
        }
      }
    }

    @media (max-width: $container-max-width + 300px) and (min-width: 768px) {
      // Comments that are far left
      .commentsList .commentDonation--hard-left .commentContentWrapper {
        transform: translateX(-18px);

        &:before {
          left: 20px;
        }
      }

      // Comments that are far right
      .commentsList .commentDonation--hard-right .commentContentWrapper {
        transform: translateX(calc(-100% + 33px));

        &:before {
          right: 20px;
        }
      }
    }

    .commentsList .commentContent {
      background: transparent;
      padding: 0;

      p {
        margin: 0 0 10px;
      }
    }

    .commentsList .commentDetails {
      p.commenterWrapper {
        margin: 0;
        text-transform: none;
        font-weight: bold;
      }

      p.commentDate {
        display: none;
      }
    }

    .commentsList .commentDonationAmount {
      display: none;
    }

    // Comments are cloned into this alternative ul which is printed out below the icon field
    ul.commentsList--printed {
      display: flex;
      flex-direction: column;
      padding-left: 0;

      li.commentDonation {
        list-style-type: none;
        border-top:1px solid rgba(255,255,255,.5);
        padding:20px;
        img.commentProfilePic{
          display:none;
        }
        // Responsive comment section
        @media (max-width: map-get($breakpoints, md)) {
          padding: 20px 0;
          display: flex; 
          flex-direction: column-reverse;
          .commentContentWrapper { width: 100%; }
          .commentDonationAmount { margin-bottom: 10px; }
          .commentContent p { font-size: 0.95rem; }
        }
      }

      .commentContentWrapper{
        width:75%;
        float:left;
      }

      .commentContent {
        background-color: transparent;
        padding: 0;
        p{
          font-weight: bold;
          font-size:1.4em;
        }
      }

      .commenterWrapper{
        text-transform: uppercase;
        margin-bottom:10px;
        strong{
          display: none;
        }
      }

      .commentDate strong{
        display: none;
      }

      .commentDonationAmount{
        float:right;
        font-size: 1.4em;
        font-family: 'VAG Rounded Next W05 Regular', sans-serif;
        color:white;
      }

    }

    .postCommentsAlt {
      text-align: center;

      // We actually want to hide this from the user
      // We're clicking this with JS if it exists and loading more stars automatically
      opacity: 0;
      visibility: hidden;
      height: 0;
    }

    .makeCommentAlt {
      display: none; // Hide the 'Add a comment' button beside load more

    }

    // Hide Comments and donations box on appeal post
    h3#makeComment,
    .commentForm {
      display: none;
    }
  }

  body.donate.#{$body-class} {

    #Single.active, #Regular.active {
      background-color: $appeal-content-colour;
    }

    .headerText h1,
    .headerText .listingIntro {
      color: $white;
    }

    .totaliserPanel {
      display: none;
    }

    .donationForm {
      margin-bottom: 0;
    }

    .yourDonation, .paymentForm, .registerForm {
      background: $white;
    }

    .selectDonationAmount {
      & > span {
        width: 100%;
        display: block;
      }
    }

    .contentBlockWrapper > section {
      background:white;
    }

    .donationAmount:not(.donationAmountOther):not(.ccAmount){
      // width: 200px;
      display: inline-block;
      margin: 20px 0;
      background-color: transparent;
      padding: 0;
      cursor: pointer;

      // This is the icon behind each donation amount
      // TODO: This needs refactoring to $icon-image from fontawesome star
      // &:before {
      //   content: "";
      //   width: 160px;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   height: 150px;
      //   z-index: 0;
      //   background-image: url($assets-path + "forget-me-not.png");
      //   background-position: center center;
      //   background-repeat: no-repeat;
      //   transform: translate(-50%, -50%);
      // }

      @media only screen and (max-width: 768px) {
        display: block;
        margin: 20px auto;
      }

      input[type="radio"] {
        visibility: hidden;
        display: none;
      }

      // .donationAmountFigure {
      //   border: 0;
      //   width: 200px;
      //   height: 185px;
      //   color: $brand-primary;
      //   position: relative;
      //   z-index: 2;
      //   padding-top: 80px;
      //   margin: 0;
      //   font-size: 2em;
      //   cursor: pointer;
      // }

      .donationAmountDescription {
        color: $body-colour;
        padding: 0;
        margin: 20px 0;
        display: block;
        text-align: center;
        width: 100%;
      }

      // &.donationSelected {
      //   background-color: transparent;

      //   &:before {
      //     background-image: url($assets-path + "flower-donation-selected.svg");
      //   }

      //   .donationAmountFigure {
      //     color: $white;
      //   }
      // }
    }

    #covercosts {
      margin-top: 0;
    }

    .terms-and-conditions-wrapper p {
      color: $white;
    }

    button.donate {
      padding: 10px 30px;
      background-color: $cta-btn-background-colour;
    }
  }
}

@include appeal-field(
  $body-class: 'support-say-their-name',
  $background-colour: #225310,
  $background-image: 'bg.jpg',
  $text-colour: $white,
  $icon-image: 'forget-me-not.png',
  $icon-size: 80px,
  $icon-mobile-size: 40px,
  $icon-animation: boogie 4s linear 0s infinite normal,
  $appeal-content-colour: $brand-primary
);

// This is the animation for the snowdrop icons

@-webkit-keyframes boogie {
  0% {
    -webkit-transform: rotate(-5deg);
    -webkit-transform-origin: 50% 50%;
  }

  25% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
  }

  50% {
    transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
    -webkit-transform-origin: 50% 50%;
  }

  75% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
  }

  100% {
    -webkit-transform: rotate(-5deg);
    -webkit-transform-origin: 50% 50%;
  }
}

@-moz-keyframes boogie {

  0% {
    -moz-transform: rotate(-5deg);
    -moz-transform-origin: 50% 50%;
  }

  25% {
    -moz-transform: rotate(0deg);
    -moz-transform-origin: 50% 50%;
  }

  50% {
    -moz-transform: rotate(5deg);
    -moz-transform-origin: 50% 50%;
  }

  75% {
    -moz-transform: rotate(0deg);
    -moz-transform-origin: 50% 50%;
  }

  100% {
    -moz-transform: rotate(-5deg);
    -moz-transform-origin: 50% 50%;
  }
}
